import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import SmoothScroll from '~/components/Common/SmoothScroll'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import Layout from '~/components/Common/Layout'
import IconButtonArrow from '~/images/icon/button-arrow.svg'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem, replaceImage } from '~/utils/helper'

const eventDetailStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 56px);

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 34px;
    padding-left: 34px;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-right: 2px;
    padding-left: 2px;
  }

  & > .contentwrapper {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
    padding: 32px 24px 80px;
    border-radius: 16px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.PC}) {
      display: grid;
      grid-template-areas:
        'title title'
        'outline form'
        'speaker form'
        'button button';
      grid-template-columns: 490px 360px;
      padding: 48px 48px 80px;
      column-gap: 40px;
      row-gap: 48px;
    }
  }

  & > .contentwrapper > .title {
    @media screen and (${STYLE.MEDIA.PC}) {
      grid-area: title;
    }
  }

  & > .contentwrapper > .title > .text {
    font-size: ${rem(28)};
    font-weight: bold;
    line-height: 1.5;
  }

  & > .contentwrapper > .title > .date {
    display: block;
    margin-top: 24px;
    font-size: ${rem(20)};
    font-weight: bold;
    letter-spacing: 2px;
  }

  & > .contentwrapper > .title > .date > .datetext {
    display: inline-block;
  }

  & > .contentwrapper > .title > .image {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 48px;
    border: 1px solid var(--color-bluegray50);

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }

  & > .contentwrapper > .outline {
    padding-top: 64px;

    @media screen and (${STYLE.MEDIA.PC}) {
      grid-area: outline;
      padding-top: 0;
    }
  }

  & > .contentwrapper > .outline > .subtitle {
    margin-bottom: 16px;
    font-size: ${rem(20)};
    font-weight: bold;
    line-height: 1.2;

    &:not(:first-of-type) {
      margin-top: 48px;
    }
  }

  & > .contentwrapper > .outline img {
    max-width: 100%;
  }

  & > .contentwrapper > .outline > .list > .listitem {
    padding-left: 1em;
    font-size: ${rem(16)};
    line-height: 1.5;
    text-indent: -1em;

    &::before {
      content: '・';
    }
  }

  & > .contentwrapper > .outline > .note {
    padding-left: 1em;
    font-size: ${rem(14)};
    line-height: 1.71;
    text-indent: -1em;

    &::before {
      content: '※';
    }
  }

  & > .contentwrapper > .outline > .text {
    font-size: ${rem(16)};
    line-height: 1.5;
  }

  & > .contentwrapper > .speaker {
    padding-top: 48px;

    @media screen and (${STYLE.MEDIA.PC}) {
      grid-area: speaker;
      padding-top: 0;
    }
  }

  & > .contentwrapper > .speaker > .title {
    margin-bottom: 16px;
    font-size: ${rem(20)};
    font-weight: bold;
    line-height: 1.2;

    &:not(:first-of-type) {
      margin-top: 48px;
    }
  }

  & > .contentwrapper > .speaker > .text {
    color: var(--color-grey700);
    font-size: ${rem(16)};
    line-height: 1.5;
  }

  & > .contentwrapper > .speaker > .list {
    margin-top: 16px;
  }

  & > .contentwrapper > .speaker > .list > .listitem {
    padding-left: 1em;
    color: var(--color-grey700);
    font-size: ${rem(16)};
    line-height: 1.5;
    text-indent: -1em;

    &::before {
      content: '・';
    }
  }

  & > .contentwrapper > .speaker > .speakerlist {
    padding-bottom: 64px;

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-bottom: 0;
    }
  }

  & > .contentwrapper > .speaker > .speakerlist > .listitem {
    display: flex;
    flex-wrap: wrap;

    &:not(:first-of-type) {
      margin-top: 40px;

      @media screen and (${STYLE.MEDIA.PC}) {
        margin-top: 48px;
      }
    }
  }

  & > .contentwrapper > .speaker > .speakerlist > .listitem > .image {
    width: 23%;
    height: 100%;
    margin-right: 24px;
  }

  & > .contentwrapper > .speaker > .speakerlist > .listitem > .biography {
    width: calc(77% - 24px);
  }

  & > .contentwrapper > .speaker > .speakerlist > .listitem > .biography > .title {
    margin-bottom: 16px;
    font-size: ${rem(16)};
    font-weight: bold;
    line-height: 1.5;
  }

  & > .contentwrapper > .speaker > .speakerlist > .listitem > .biography > .text {
    color: var(--color-grey700);
    font-size: ${rem(16)};
    line-height: 1.5;
  }

  & > .contentwrapper > .speaker > .freearea {
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 0;
    }
  }

  & > .contentwrapper > .form {
    @media screen and (${STYLE.MEDIA.PC}) {
      grid-area: form;
    }
  }

  & > .contentwrapper > .form > .body {
    width: 100vw;
    margin: 80px calc(50% - 50vw) 0;
    padding: 24px 24px 80px;
    border-radius: 30px;
    background: var(--color-bluegray50);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: auto;
      margin: 0;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      padding: 24px 40px 40px;
    }
  }

  & > .contentwrapper > .form > .body > .subtitle {
    margin-bottom: 16px;
    color: var(--color-key);
    font-size: ${rem(16)};
    font-weight: bold;
    line-height: 1.6875;
  }

  /* イベント受付終了時 */
  & > .contentwrapper > .form > .body.closed {
    padding: 24px 40px;

    @media screen and (--media-mobile) {
      /* stylelint-disable-next-line declaration-no-important */
      display: none !important;
    }
  }

  & > .contentwrapper > .form > .body.closed > .formtext {
    font-size: ${rem(24)};
    font-weight: bold;
    line-height: 1.33;
  }

  & > .contentwrapper > .button {
    padding-top: 64px;

    @media screen and (${STYLE.MEDIA.PC}) {
      grid-area: button;
      padding-top: 0;
    }
  }

  /* お申し込みボタン */
  & > .contentwrapper .pc-hide {
    @media screen and (${STYLE.MEDIA.PC}) {
      /* stylelint-disable-next-line declaration-no-important */
      display: none !important;
    }
  }

  & > .contentwrapper .sp-show {
    @media screen and (${STYLE.MEDIA.PC}) {
      display: none;
    }
  }

  & > .contentwrapper .sp-hide {
    display: none;

    @media screen and (${STYLE.MEDIA.PC}) {
      display: block;
    }
  }

  & > .contentwrapper .button-apply {
    text-align: center;
  }

  & > .contentwrapper .button-apply > .button {
    display: inline-block;
    position: relative;
    min-width: 288px;
    padding: 19px 62px 18px;
    border-radius: 32px;
    background: var(--color-background-key);
    color: var(--color-background-bright);
    font-size: rem(16px);
    font-weight: bold;
    letter-spacing: 3.2px;
    line-height: 1.6875;
    text-decoration: none;
  }

  & > .contentwrapper .button-apply > .button > .icon {
    position: absolute;
    top: 0;
    right: 32px;
    bottom: 0;
    width: 10px;
    height: 6px;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (${STYLE.MEDIA.PC}) {
      transform: scale(1, -1);
    }
  }

  /* 一覧へ戻るボタン */
  & > .contentwrapper .button-back {
    margin-top: 16px;
    text-align: center;
  }

  & > .contentwrapper .button-back > a {
    display: inline-block;
    min-width: 288px;
    padding: 19px 62px 18px;
    transition: var(--hover-button-transition);
    border: 1px solid var(--color-background-key);
    border-radius: 32px;
    font-size: rem(16px);
    font-weight: bold;
    letter-spacing: 3.2px;
    line-height: 1.6875;
    text-decoration: none;
  }

  /* ホバーイベント */
  & > .contentwrapper .button-apply > a:hover {
    opacity: var(--hover-button-opacity);
  }

  & > .contentwrapper .button-back > a:hover {
    opacity: var(--hover-button-opacity);
  }

  /* お申し込みボタンイベント受付終了時 */
  & > .contentwrapper .button-apply.closed > a {
    width: 100%;
    padding: 19px 24px 18px;
    background: var(--color-bluegray50);
    color: var(--color-text);
    pointer-events: none;
  }

  & > .contentwrapper .button-apply.closed > a > span {
    &::after {
      display: none;
    }
  }

  & > .contentwrapper .event-apply-form-close-btn {
    display: none;

    @media screen and (${STYLE.MEDIA.PC}) {
      display: block;
    }
  }
`

export const query = graphql`
  query ($id: String) {
    allFile(filter: { name: { eq: "cms-event" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          url
          name
          id
        }
      }
    }
    microcmsStgEvent(id: { eq: $id }) {
      form_id
      free_area
      event {
        explanation
        title
      }
      event_date
      event_time_end
      event_time_start
      id
      image_catch {
        url
        height
        width
      }
      speakers {
        belongs
        biography
        name
        photo {
          height
          url
          width
        }
        position
      }
      status
      stgEventId
      title
      seo_title
      seo_description
    }
  }
`

const EventDetailPage = ({ data }: { data: Query }) => {
  const pageData = data.microcmsStgEvent
  if (!pageData) return

  const breadcrumb = [
    {
      label: 'セミナー・イベント',
      path: '/event',
    },
    {
      label: pageData.title || '',
    },
  ]
  const seo = {
    title: `${pageData.seo_title || pageData.title} | セミナー・イベント`,
    description: pageData.seo_description || '',
    ogImage: pageData.image_catch && pageData.image_catch.url ? pageData.image_catch && pageData.image_catch.url : '',
  }
  const gatsbyImageData =
    pageData.image_catch && pageData.image_catch.url ? getGatsbyImageData(data.allFile, pageData.image_catch.url) : null
  const isRecruiting = String(pageData.status) === 'お申し込み受付中'
  if (isRecruiting && process.env.HUBSPOT_PORTAL_ID && pageData.form_id) {
    useHubspotForm({
      portalId: process.env.HUBSPOT_PORTAL_ID,
      formId: pageData.form_id,
      target: '#hubspot-form',
    })
  }
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={eventDetailStyle}>
        <div className="contentwrapper">
          <div className="title">
            <h1 className="text">{pageData.title}</h1>
            <time className="date">
              <span className="datetext">{pageData.event_date}</span>
              <span className="datetext">
                {pageData.event_time_start}
                {pageData.event_time_end}
              </span>
            </time>
            <GatsbyImage className="image" image={gatsbyImageData} alt={pageData.title || ''} />
          </div>
          {isRecruiting ? (
            <div className="button-apply sp-show event-apply-open-btn">
              <SmoothScroll className="button" to="form" href="#form">
                お申し込みはこちら
                <IconButtonArrow className="icon" />
              </SmoothScroll>
            </div>
          ) : (
            <div className="button-apply sp-show closed event-apply-close-btn">
              <a href="#form">
                <span>本イベントの受付は終了しました</span>
              </a>
            </div>
          )}
          <div className="outline">
            {pageData.event &&
              pageData.event.length !== 0 &&
              pageData.event.map((event, index) => {
                if (!event) return
                return (
                  <React.Fragment key={index}>
                    {event.title && <h2 className="subtitle">{event.title}</h2>}
                    {event.explanation && <div className="text">{replaceImage(event.explanation, data.allFile)}</div>}
                  </React.Fragment>
                )
              })}
          </div>
          <div className="speaker">
            {pageData.speakers &&
              pageData.speakers.length !== 0 &&
              pageData.speakers.map((speaker, index) => {
                if (!speaker || !speaker.photo || !speaker.photo.url || !speaker.name) return
                const gatsbyImageSpeakerData = getGatsbyImageData(data.allFile, speaker.photo.url)
                return (
                  <React.Fragment key={index}>
                    <h2 className="title">登壇者</h2>
                    <ul className="speakerlist">
                      <li className="listitem">
                        <GatsbyImage className="image" image={gatsbyImageSpeakerData} alt={speaker.name} />
                        <div className="biography">
                          <h3 className="title">{speaker.name}</h3>
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: `${speaker.belongs}${speaker.position} ${speaker.biography}`,
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </React.Fragment>
                )
              })}
            {isRecruiting && (
              <div className="button-apply sp-show pc-hide event-apply-middle-open-btn">
                <SmoothScroll className="button" to="form" href="#form">
                  お申し込みはこちら
                  <IconButtonArrow className="icon" />
                </SmoothScroll>
              </div>
            )}
            {pageData.free_area && (
              <div
                className="freearea"
                dangerouslySetInnerHTML={{
                  __html: `${pageData.free_area}`,
                }}
              />
            )}
          </div>
          <div className="form" id="form">
            {isRecruiting ? (
              <div className="body event-apply-form-open-btn">
                <h2 className="subtitle">お申し込みフォーム</h2>
                <div id="hubspot-form" className="body"></div>
              </div>
            ) : (
              <div className="body closed event-apply-form-close-btn">
                <p className="formtext">
                  本イベントの受付は
                  <br />
                  終了しました
                </p>
              </div>
            )}
          </div>
          <div className="button">
            {isRecruiting && (
              <div className="button-apply sp-hide event-apply-under-close-btn">
                <SmoothScroll className="button" to="form" href="#form">
                  お申し込みはこちら
                  <IconButtonArrow className="icon" />
                </SmoothScroll>
              </div>
            )}
            <div className="button-back">
              <Link to="/event">一覧へ戻る</Link>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default EventDetailPage
